import React from 'react';
import './Project.css';
import code_img from './../../assets/code.png';
import cursor from './../../assets/cursor.png';
import react_img from './../../assets/react.png';
import js from './../../assets/js.png';
import css from './../../assets/css.png';
import cs from './../../assets/c-sharp.png';
import node from './../../assets/node-js.png';
import postgre from './../../assets/postgre.png';
import unity from './../../assets/unity.png';
import tailwind from './../../assets/tailwind.png';
import python from './../../assets/python.png';

const techImages = {
  react: react_img,
  js: js,
  css: css,
  cs: cs,
  node: node,
  postgre: postgre,
  unity: unity,
  tailwind: tailwind,
  python: python
};

export const Project = ({ name, description, img, code, demo, techStack, openModal }) => {
  return (
    <div className="project">
      <img alt={name} src={img} className="project-image" onClick={() => openModal(img)} />
      <h3 className="project-name">{name}</h3>
      <p className="project-description">{description}</p>
      {techStack && (
        <div className="tech-stack">
          {techStack.map((tech) => (
            <img key={tech} src={techImages[tech]} alt={tech} className="tech-icon" />
          ))}
        </div>
      )}
      {code ? <><a href={code} target="_blank" rel="noopener noreferrer"><button className='project-link'><img alt="code" src={code_img}/></button></a></> : <></>}
      {demo ? <><a href={demo} target="_blank" rel="noopener noreferrer"><button className='project-link'><img alt="demo" src={cursor}/></button></a></> : <></>}
    </div>
  );
};
