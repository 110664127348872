import candiefy from './assets/candiefy-home.png'
import huginn from './assets/huginn-care.png'
import scav from './assets/scavcat.png'
import rod from './assets/rodMaster.png'
import movie from './assets/movie-home.png'
import firesale from './assets/firesale.jpg'

export const PROJECTS = [
    {
      id: 1,
      name: "Candiefy",
      description: "A simple e-commerce website. It features a user-friendly interface for browsing products, managing a shopping cart, and completing the checkout process with ease.",
      img: candiefy,
      code: "https://github.com/lorraineros/Candiefy",
      demo: "https://candiefy.vercel.app/",
      techStack: ["react", "js"]
    },
    {
      id: 2,
      name: "Huginn Care",
      description: "The Huginn Care app, created with Jökulá, was designed to improve operations in residential care facilities. It makes the recording and management of patient data quicker. The app allows for the registration and storage of diary entries, incident reports, medication records, reports, and much more.",
      img: huginn,
      code: "https://github.com/astaduna/HuginnCare",
      demo: "",
      techStack: ["react", "node", "js", "postgre"]
    },
    {
      id: 3,
      name: "Movies",
      description: "Responsive web application that allows users to search for movies, view detailed information about selected movies, and manage a list of favorite movies. The application integrates with a movie database API (TMDB) to fetch real-time data, including movie details, credits, trailers, and similar movies.",
      img: movie,
      code: "https://github.com/lorraineros/movies",
      demo: "https://movies-lor-proj.vercel.app/",
      techStack: ["react", "js", "tailwind"]
    },
    {
      id: 4,
      name: "Fire Sale",
      description: "A mini-replica of the giant platform Craigslist, allowing user to sell items while allowing other users to send offers in order to purchase the item.",
      img: firesale,
      code: "https://github.com/anhthuvu512/FireSale",
      techStack: ["python", "postgre"]
    },
    {
      id: 5,
      name: "Scavenger Cat",
      description: "A 2D Platformer game where a cat escapes home to find fresh food. Journey from city streets to jungles, encounter enemies like evil cats and dogs. With levels increasing in difficulty, win by collecting all available food items.",
      img: scav,
      code: "https://github.com/gylfinn/scavenger-cat",
      demo: "https://gylfi12345.itch.io/scavenger-cat-final?secret=KqmIo3Q2Es53kKAXX0OcR4S6oU8",
      techStack: ["unity", "cs"]
    },
    {
      id: 6,
      name: "Rod Master",
      description: "A relaxing 2D fishing game where you navigate the ocean, catch fish, and avoid junk to prevent fish from escaping. Earn money to upgrade your rod, and check the fish glossary to track your catches and their value.",
      img: rod,
      code: "https://github.com/RedIceGuy/RodMaster",
      demo: "https://eythorh20.itch.io/rod-master",
      techStack: ["unity", "cs"]
    },
]